<template>
  <div class="list-approval-page">
    <KTCodePreview
      v-bind:title="'Danh sách giao dịch sản phẩm phần mềm đối tác'"
    >
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="search-col">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
<!--          <b-col>-->
<!--            <Autosuggest-->
<!--              class="border-radius-none"-->
<!--              :model="searchStore"-->
<!--              :suggestions="filteredOptionStore"-->
<!--              placeholder="cửa hàng"-->
<!--              :size="200"-->
<!--              @select="onSelectedStore"-->
<!--              @change="onInputChangeStore"-->
<!--              suggestionName="suggestionName"-->
<!--              :disabled="disableStore"-->
<!--            />-->
<!--          </b-col>-->
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="billNumber"
              type="text"
              placeholder="Số hóa đơn"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <!--          <b-col>-->
          <!--            <b-form-select-->
          <!--              size="sm"-->
          <!--              v-model="selectedStatus"-->
          <!--              :options="listProviders"-->
          <!--              value-field="id"-->
          <!--              text-field="name"-->
          <!--              class="select-style"-->
          <!--            ></b-form-select>-->
          <!--          </b-col>-->
          <!--          <b-col>-->
          <!--            <b-form-select-->
          <!--              size="sm"-->
          <!--              v-model="selectedStatus"-->
          <!--              :options="listStatuses"-->
          <!--              value-field="id"-->
          <!--              text-field="name"-->
          <!--              class="select-style"-->
          <!--            ></b-form-select>-->
          <!--          </b-col>-->
        </b-row>

        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc
            </b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(billNumber)="row">
            <div
              style="justify-content: left; align-items: center; display: grid"
            >
              <a
                class="font-weight-bold"
                v-bind:href="getLinkDoc(row.item.bill.billNumber)"
              >
                {{ row.item.bill.billNumber }}
              </a>
              <span v-text="row.item.store.name"></span>
            </div>
          </template>
          <template v-slot:cell(customerInfo)="row">
            <div
              style="justify-content: left; align-items: center; display: grid"
            >
              <span v-text="row.item.customer.fullName"></span>
              <span
                v-text="
                  `Số điện thoại: ${formatStringInfo(
                    row.item.customer.phoneNo,
                  )}`
                "
              ></span>
              <span
                v-text="`Email: ${formatStringInfo(row.item.customer.email)}`"
              ></span>
            </div>
          </template>

          <template v-slot:cell(totalAmount)="row">
            <div
              style="justify-content: left; align-items: center; display: grid"
            >
              {{ `${convertPrice(row.item.bill.totalAmount)} VNĐ ` }}
            </div>
          </template>
          <template v-slot:cell(provider)="row">
            <div>{{ row.item.provider }}</div>
            <div>{{ `Số đơn hàng: ${row.item.providerInfo.orderNumber}` }}</div>
          </template>
          <template v-slot:cell(status)="row">
            <div class="d-block text-center">
              <div
                v-text="
                  getStatusName({
                    value: row.item.status,
                    referenceStatusName: MICROSOFT_REQUEST_KEYS_STATUS_NAME,
                  })
                "
                v-bind:class="bindClassNameStatus(row.item.status)"
              ></div>
            </div>
          </template>
          <template v-slot:cell(createdBy)="row">
            <p>{{ row.item.employee.name }}</p>
            <p>
              {{
                formatDate(
                  row.item.bill.createdAt,
                  CLIENT_DATE_TIME_NO_SECONDS(),
                )
              }}
            </p>
          </template>
<!--          <template v-slot:cell(actions)="row">-->
<!--            <div class="text-center">-->
<!--              <b-dropdown size="sm" id="dropdown-left" no-caret right>-->
<!--                <template slot="button-content">-->
<!--                  <i-->
<!--                    style="font-size: 1rem; padding-right: 0px"-->
<!--                    class="flaticon2-settings"-->
<!--                  ></i>-->
<!--                </template>-->
<!--                <b-dropdown-item-->
<!--                  @click="editItem(row.item)"-->
<!--                >-->
<!--                  <span class="dropdown-item-text">-->
<!--                    <i class="flaticon-eye"></i>-->
<!--                    &nbsp; Chi tiết-->
<!--                  </span>-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                  @click="showChangeStatusModal(row.item)"-->
<!--                >-->
<!--                  <span class="dropdown-item-text">-->
<!--                    <i-->
<!--                      style="font-size: 1rem"-->
<!--                      class="fas fa-sync-alt icon-color"-->
<!--                    ></i>-->
<!--                    &nbsp; Thử lại-->
<!--                  </span>-->
<!--                </b-dropdown-item>-->
<!--                <b-dropdown-item-->
<!--                  @click="showWarningAlert(row.item, 'return')"-->
<!--                  v-if="isShowReturnRequest(row.item.status)"-->
<!--                >-->
<!--                  <span class="dropdown-item-text">-->
<!--                    <i style="font-size: 1rem" class="flaticon2-reply-1"></i>-->
<!--                    &nbsp; Hoàn duyệt-->
<!--                  </span>-->
<!--                </b-dropdown-item>-->
<!--              </b-dropdown>-->
<!--            </div>-->
<!--          </template>-->
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
import localData from '@/utils/saveDataToLocal';
import { APPROVAL_PAYMENT_TYPE, APPROVAL_TYPE } from '@/utils/constants';
import 'vue-cute-timeline/dist/index.css';
import {
  convertPrice,
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
} from '@/utils/common';
// @TODO: review selected store
// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import timeUtils, {
  CLIENT_DATE_TIME_NO_SECONDS,
  formatDate,
} from '@/utils/date';
import { APPROVAL_STEP_STATUS } from '@/utils/enum';
import {
  SOFTWARE_PROVIDER_FIELDS,
  SOFTWARE_PROVIDERS,
} from '@/view/pages/software-product-providers/utils/constants';
import { SearchSoftwareProductTransaction } from '@/view/pages/software-product-providers/models/SearchSoftwareProductTransaction';
import { SoftwareProductTransaction } from '@/view/pages/software-product-providers/models/SoftwareProductTransaction';
import { MICROSOFT_REQUEST_KEYS_STATUS } from '@/view/pages/software-product-providers/utils/enum';
import { MICROSOFT_REQUEST_KEYS_STATUS_NAME } from '@/view/pages/software-product-providers/utils/enum-name';

export default {
  computed: {
    MICROSOFT_REQUEST_KEYS_STATUS_NAME() {
      return MICROSOFT_REQUEST_KEYS_STATUS_NAME;
    },
  },
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: timeUtils.DP_CONFIG.date,
      onLoading: false,
      listItem: [],
      searchDescription: '',
      searchStore: '',
      searchCode: '',
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      fields: SOFTWARE_PROVIDER_FIELDS,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selectedStoreId: null,
      optionsStore: [],
      filteredOptionStore: [],
      size: 10,
      selectedStatus: null,
      selectedProvider: null,
      listProviders: SOFTWARE_PROVIDERS,
      listStatuses: [],
      selectedType: null,
      listType: APPROVAL_TYPE,
      selectedEmployeeId: null,
      optionsEmployee: [],
      filteredEmployeeOptions: [],
      searchEmployee: '',
      disableStore: false,
      selectedPaymentType: null,
      listPaymentType: APPROVAL_PAYMENT_TYPE,
      entityUploadId: null,
      billNumber: '',
    };
  },
  methods: {
    CLIENT_DATE_TIME_NO_SECONDS() {
      return CLIENT_DATE_TIME_NO_SECONDS;
    },
    formatDate,
    currencyMask,
    convertPrice,

    fetchData: async function () {
      this.onLoading = true;
      const params = this.getParamFilter();

      ApiService.query('/microsoft-key-online/transactions', { params })
        .then((response) => {
          const responseData = response.data.data;
          this.totalPages = responseData.totalPages;
          this.totalItems = responseData.count;
          this.listItem = responseData.data.map((item, index) => {
            return new SoftwareProductTransaction({
              ...item,
              count: index + 1,
            });
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'info-approval',
        query: { id: item.id },
      });
    },
    onSelectedStore(option) {
      this.selectedStoreId = option.item.id;
      this.searchStore = option.item.name;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStore = text;
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.size);

      this.filteredOptionStore = [...filteredData];
    },
    showWarningAlert: function (item, action = 'delete') {
      const title =
        action === 'return'
          ? 'Hoàn duyệt yêu cầu duyệt chi!'
          : 'Xóa phiếu yêu cầu duyệt chi!';
      const text =
        action === 'return'
          ? 'Bạn có chắc muốn hoàn duyệt yêu cầu duyệt duyệt chi này?'
          : 'Bạn có chắc muốn xóa phiếu yêu cầu duyệt chi này?';
      const confirmText = action === 'return' ? 'Đồng ý' : 'Xóa';
      const optionsNotify = {
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: confirmText,
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      };

      Swal.fire(optionsNotify).then((result) => {
        if (result.value) {
          action === 'return'
            ? this.cancelRequest(item)
            : this.deleteItem(item);
        }
      });
    },
    cancelRequest: async function (item) {
      const url = `v2/approvals/${item.id}/return`;
      const method = 'put';
      ApiService[method](url)
        .then(({ data }) => {
          makeToastSuccess(data.message);
          this.fetchData();
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message || 'Lỗi!';
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    deleteItem: async function (item) {
      ApiService.delete(`v2/approvals/${item.id}`).then(({ data }) => {
        if (data.status === 200) {
          makeToastSuccess(data.message);
          this.fetchData();
        } else {
          makeToastFaile(data.message);
        }
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'software-product-providers',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getLinkDoc(value) {
      return `#/bills/update-bill?id=${value}`;
    },
    bindClassNameStatus(apiStatus) {
      let className = '';
      switch (apiStatus) {
        case MICROSOFT_REQUEST_KEYS_STATUS.SUCCESSFUL: {
          className = 'badge badge-success';
          break;
        }
        case MICROSOFT_REQUEST_KEYS_STATUS.SYSTEM_ERROR: {
          className = 'badge badge-warning';
          break;
        }
        case MICROSOFT_REQUEST_KEYS_STATUS.EXCEED_CREDIT_LIMIT:
        case MICROSOFT_REQUEST_KEYS_STATUS.REQUESTED_KEYS_EXCEEDS_PER_REQUEST:
        case MICROSOFT_REQUEST_KEYS_STATUS.REQUESTED_KEYS_EXCEEDS_PER_DAY:
        case MICROSOFT_REQUEST_KEYS_STATUS.USED_TRANSACTION_ID:
        case MICROSOFT_REQUEST_KEYS_STATUS.OUT_OF_SERVICE: {
          className = 'badge badge-info';
          break;
        }
        case MICROSOFT_REQUEST_KEYS_STATUS.INVALID_USERNAME:
        case MICROSOFT_REQUEST_KEYS_STATUS.INVALID_SECURITY_TOKEN:
        case MICROSOFT_REQUEST_KEYS_STATUS.INVALID_SKU: {
          className = 'badge badge-danger badge-warning';
          break;
        }
      }
      return className;
    },
    showChangeStatusModal(approvalModel) {
      this.$refs['approval-change-status'].showModal(approvalModel);
    },
    isShowChangeStatus(approvalStatus) {
      if (!this.checkPermission('APPROVAL_UPDATE')) {
        return false;
      }
      const acceptStatusChanges = [
        APPROVAL_STEP_STATUS.APPROVED,
        APPROVAL_STEP_STATUS.PAID,
        APPROVAL_STEP_STATUS.COMPLETED,
      ];
      return acceptStatusChanges.includes(approvalStatus);
    },
    isShowReturnRequest(approvalStatus) {
      if (!this.checkPermission('APPROVAL_APPROVE')) {
        return false;
      }
      return (
        approvalStatus === APPROVAL_STEP_STATUS.APPROVED ||
        approvalStatus === APPROVAL_STEP_STATUS.ON_APPROVE
      );
    },
    getParamFilter() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      return new SearchSoftwareProductTransaction({
        page: this.page,
        size: 10,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        billNumber: this.billNumber || '',
        module: 'request-keys',
      });
    },
    formatStringInfo(value) {
      if (!value || !value.length) {
        return 'Không có thông tin';
      }

      if (typeof value !== 'string') {
        console.warn('Thông tin không đúng định dạng');
        return 'Không có thông tin';
      }

      return value;
    },
    getStatusName({ value, referenceStatusName }) {
      if (!referenceStatusName[value]) {
        console.warn(
          'referenceStatusName does not contain the specified value',
        );
      }

      return referenceStatusName[value] || '';
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách giao dịch sản phẩm phần mềm Microsoft' },
    ]);
    this.fetchData();
  },
  created() {
    // this.listProviders.unshift({
    //   id: null,
    //   name: 'Chọn nhà cung cấp',
    // });
    // this.listStatuses.unshift({
    //   id: null,
    //   name: 'Chọn trạng thái',
    // });
  },
  watch: {
    // selectedProvider: function (prevVal, newVal) {
    //   if (prevVal !== newVal) {
    //     const clonedStatuses = [...this.listStatuses];
    //
    //     switch (newVal) {
    //       case 'MICROSOFT':
    //         this.listStatuses = clonedStatuses[newVal];
    //         break;
    //     }
    //   }
    // },
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    // Autosuggest,
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
</style>
<style lang="scss">
.list-approval-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }

  .dropdown-item {
    padding: 0px;
  }

  .search-col {
    display: flex;
    align-items: center;
  }
}
</style>
