export const MICROSOFT_REQUEST_KEYS_STATUS = Object.freeze({
  SYSTEM_ERROR: -1,
  SUCCESSFUL: 0,
  INVALID_USERNAME: 1,
  INVALID_SECURITY_TOKEN: 2,
  INVALID_SKU: 3,
  EXCEED_CREDIT_LIMIT: 4,
  REQUESTED_KEYS_EXCEEDS_PER_REQUEST: 5,
  USED_TRANSACTION_ID: 7,
  OUT_OF_SERVICE: 8,
  REQUESTED_KEYS_EXCEEDS_PER_DAY: 9,
});
