import { convertPrice } from '@/utils/common';

export const SOFTWARE_PROVIDERS = [
  {
    id: 'MICROSOFT',
    name: 'Microsoft ESD',
  },
];

export const SOFTWARE_PROVIDER_STATUS = Object.freeze({
  MICROSOFT: [
    { id: -1, name: 'Lỗi hệ thống' },
    { id: 0, name: 'Thành công' },
    { id: 1, name: 'Sai tên người dùng hệ thống' },
    { id: 2, name: 'Sai token hệ thống' },
    { id: 3, name: 'Sai sản phẩm SKU' },
    { id: 4, name: 'Tổng giá trị sản phẩm vượt quá credit limit' },
    {
      id: 5,
      name: 'Số lượng yêu cầu key sản phẩm vượt quá giới hạn trong một lần yêu cầu',
    },
    { id: 7, name: 'ID hóa đơn đã được gửi cần tạo hóa đơn mới' },
    { id: 8, name: 'Không nằm trong khung thời gian phục vụ' },
    { id: 9, name: 'Số lượng yêu cầu key vượt quá giới hạn trong một ngày' },
  ],
});

export const SOFTWARE_PROVIDER_FIELDS = [
  {
    key: 'billNumber', // billNumber
    label: 'Số hóa đơn',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'customerInfo', // customerInfo
    label: 'Thông tin khách hàng',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'totalAmount', // status
    label: 'Tổng tiền',
    thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
    tdClass: 'text-right',
    formatter: (value) => {
      return value ? convertPrice(value) : 0;
    },
  },
  {
    key: 'provider',
    label: 'Thông tin từ NCC',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'status',
    label: 'Trạng thái',
    thStyle: {
      fontWeight: 600,
      color: '#181c32',
      width: '10%',
      textAlign: 'center',
    },
  },
  {
    key: 'createdBy',
    label: 'Người phụ trách',
    thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
  },
  {
    key: 'actions',
    label: '',
    thStyle: {
      width: '10%',
    },
    tdClass: 'align-middle',
  },
];
