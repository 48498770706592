import { MICROSOFT_REQUEST_KEYS_STATUS } from '@/view/pages/software-product-providers/utils/enum';

export const MICROSOFT_REQUEST_KEYS_STATUS_NAME = Object.freeze({
  [MICROSOFT_REQUEST_KEYS_STATUS.SYSTEM_ERROR]: 'Lỗi hệ thống',
  [MICROSOFT_REQUEST_KEYS_STATUS.SUCCESSFUL]: 'Thành công',
  [MICROSOFT_REQUEST_KEYS_STATUS.INVALID_USERNAME]:
    'Sai tên người dùng hệ thống',
  [MICROSOFT_REQUEST_KEYS_STATUS.INVALID_SECURITY_TOKEN]: 'Sai token hệ thống',
  [MICROSOFT_REQUEST_KEYS_STATUS.INVALID_SKU]: 'Sai sản phẩm SKU',
  [MICROSOFT_REQUEST_KEYS_STATUS.EXCEED_CREDIT_LIMIT]:
    'Tổng giá trị sản phẩm vượt quá credit limit',
  [MICROSOFT_REQUEST_KEYS_STATUS.REQUESTED_KEYS_EXCEEDS_PER_REQUEST]:
    'Số lượng yêu cầu key sản phẩm vượt quá giới hạn trong một lần yêu cầu',
  [MICROSOFT_REQUEST_KEYS_STATUS.USED_TRANSACTION_ID]: 'ID hóa đơn đã được gửi',
  [MICROSOFT_REQUEST_KEYS_STATUS.OUT_OF_SERVICE]:
    'Không nằm trong khung thời gian phục vụ',
  [MICROSOFT_REQUEST_KEYS_STATUS.REQUESTED_KEYS_EXCEEDS_PER_DAY]:
    'Số lượng yêu cầu key vượt quá giới hạn trong một ngày',
});
